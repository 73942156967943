@media (min-width: 1024px) and (max-width: 1399px) {
    .navigation .nav-item a{
        margin-left: 10px;
        font-size: 14px;
        white-space: nowrap;
    }
    .navigation::after{
        width: 20% !important;
    }
    .navigation .navbar-brand img{
       max-width: 140px;
    }
    .navigation .nav-item.user-profile a {
        margin-left: 20px;
    }
     .bill-body-text p {
        font-size: 13px;
    }
    .sponcer-text-sec-inner label{
        font-size: 12px;
    }
    .sponcer-text-sec-inner p{
        font-size: 12px;
    }
    .login-background {
        height: 100%;
        padding: 20px 10px;
    }
    /* logged-issue */
    .project-table table thead tr th{
        white-space: nowrap;
    }
    .project-table table tbody tr td{
        white-space: nowrap;
    }
    /* member */
    .member-name-one ul li {
        padding: 15px 20px;
        font-size: 13px;
        margin-right: 15px;
    }
    .member-info-text span {
        font-size: 12px;
    }
    .member-info h5 {
        font-size: 12px;
    }
    .member-name-one div{
        font-size: 13px;
        white-space: nowrap;
    }
    /* otp */
    .otp-ban{
        height: 100vh;
    }
    /* contact */
    .contact-page{
        height: 100%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .navigation .navbar-brand img{
       max-width: 130px;
    }
    .login-radio-tab .form-check label{
        font-size: 15px;
        white-space: nowrap;
    }
     /* logged-issue */
     .project-table table thead tr th{
        white-space: nowrap;
    }
    .project-table table tbody tr td{
        white-space: nowrap;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .navigation .navbar-brand img{
       max-width: 120px;
    }
    .login-background{
        height: 100%;
    }
    .login-content {
        padding: 20px 0 0 0px;
    }
    .desk-mb-0 .mb-24{
        margin-bottom: 24px;
    }
    .resp-h100vh{
        height: 100vh;
    }

    /* bill */
    .navigation .navbar-light .navbar-toggler {
        background-color: transparent;
        border: none;
        padding: 10px 15px;
    }
    .navigation .navbar-light .navbar-toggler:focus{
        box-shadow: none;
    }
    .navigation .navbar-light .navbar-toggler-icon{
        background: url(../img/menu.svg) center right no-repeat;
    }
    .navigation::after{
        display: none;
    }
    .navigation .navbar-brand{
        left: -40px;
        padding: 22px 20px;
    }
    .navigation .navbar-nav{
        align-items: baseline !important;
    }
     .navigation .nav-item a{
        margin-left: 0;
        margin-top: 20px;
    }
    .navigation .nav-item.user-profile a {
        margin-left: 0;
        margin-bottom: 20px;
    }
    .resp-page{
        margin: 0px 0 30px 0;
    }
    /* upcoming-event */
    .timeline-box ul li::after{
        left: 70px;
    }
    .event-tab {
        margin: 30px 0 30px 0;
    }
    .chart-box{
        text-align: center;
    }
    .modal-button{
        width: 100% !important;
    }
    .order-xs-1{
        order: 1;
    }
    .order-xs-2{
        order: 2;
    }
    .survey-details-img{
        text-align: center;
        margin-bottom: 60px;
    }
      /* logged-issue */
      .project-table table thead tr th{
        white-space: nowrap;
    }
    .project-table table tbody tr td{
        white-space: nowrap;
    }

    /* logged-issue */
    .top-part-two ul li a {
        font-size: 11px;
    }
    .top-part-two ul li {
        margin-left: 10px;
    }
    .top-part-two li.user-btn {
        padding: 15px 5px;
    }
    /* Information-requested */
    .request-body-content-inner-two h5{
        line-height: 20px;
    }
    /* member */
    .member-name-three {
        padding: 30px 30px;
    }
    .member-name-three .br-one{
        border-right: none;
    }
     .member-name-three .member-info {
        margin-bottom: 15px;
     }
     .key-staff-body h4 {
        font-size: 15px;
    }
    .key-staff-body span {
        font-size: 12px;
    }
    .key-staff .row{
        gap: 30px 0px;
    }
    .member-name-one div{
        width: 30% !important;
        white-space: nowrap;
    }
    .member-name-two{
        overflow-x: auto;
        overflow-y: hidden ;
    }
    .member-name-one div:last-child{
        display: contents;
    }
    /* about-us */
    .about-heading {
        font-size: 35px;
    }
    .state-carousal .owl-nav .owl-prev span{
        top: 0px;
        left: -30px;
    }
     .state-carousal .owl-nav .owl-next span{
        top: 0px;
        right: -30px;
    }
    .carou-content h5 {
        font-size: 16px;
    }
    /* index */
    .banner h1 {
        font-size: 35px;
    }
    .index-card-head {
        font-size: 28px;
        line-height: 40px;
    }
    .index-inner-card{
        margin-bottom: 30px;
    }
    .index-cont .about-heading-sec{
        text-align: center !important;
    }
    .px-60 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .index-card {
        margin: 0 0 0px 0;
    }
    /* singup-two */
    .singup-two{
        padding-top: 0;
    }
    .page-body{
        padding: 30px 30px 60px 30px;
    }
    /* contact */
    .contact-page{
        height: 100%;
    }

    .comment-sec{
        height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .reply-date {
        margin-bottom: 15px;
        display: block;
    }
}
@media (min-width: 320px) and (max-width: 767px) {
    .navigation .navbar-brand img{
       max-width: 100px;
    }
    .login-background{
        height: 100%;
        padding: 20px 10px;
    }
    .login-content {
        padding: 20px 0 0 0px;
    }
    .login-radio-tab {
        display: grid;
        gap: 12px;
        justify-content: center;
    }
    .login-radio-tab .form-check label {
        font-size: 16px;
    }
    .login-page{
        text-align: center;
    }
    .login-icon {
        margin: 0 0 10px 0;
        text-align: center;
    }
    .login-icon a img {
        max-width: 200px;
    }
    .project-heading {
        font-size: 28px;
    }
    .project-subheading {
        font-size: 14px;
    }
    .project-input{
        font-size: 14px;
    }
    .project-input::placeholder{
        font-size: 14px;
    }
    .forget-sec a {
        font-size: 14px;
    }
    .forget-sec {
        padding: 8px 0px 16px 0px;
    }
    .project-btn {
        padding: 15px;
    }
    .constituent-login h1 {
        font-size: 32px;
        margin: 0 0 30px 0;
    }
    .constituent-login p {
        font-size: 14px;
        color: #fff;
    }
    .constituent-login h3 {
        font-size: 18px;
    }
    .click-login{
        margin-bottom: 30px;
        text-align: center;
    }
    .constituent-sign-up .project-heading{
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
    }
    .constituent-sign-up-img {
        text-align: center;
        margin-bottom: 30px;
    }
    .constituent-sign-up {
        padding: 30px 20px 50px 20px;
    }
    .desk-mb-0 .mb-24{
        margin-bottom: 24px;
    }
    .desk-mb-0 .project-btn.mb-24{
        margin-bottom: 0;
    }
    .click-login p{
        font-size: 14px;
    }
      /* bill */
      .navigation .navbar-light .navbar-toggler {
        background-color: transparent;
        padding: 8px 12px;
        border: none;
    }
    .navigation .navbar-light .navbar-toggler-icon{
        background: url(../img/menu.svg) center right no-repeat;
    }
    .navigation::after{
        display: none;
    }
    .navigation .navbar-brand{
        left: -15px;
        padding: 15px 20px;
        margin-top: 0;
    }
    .navigation .navbar-nav{
        align-items: baseline !important;
    }
     .navigation .nav-item a{
        margin-left: 0;
        margin-top: 10px;
        font-size: 14px;
    }
    .navigation .nav-item.user-profile a {
        margin-left: 0;
        margin-bottom: 20px;
    }
    .navigation .nav-item.user-profile a.dropdown-item{
        margin: 0;
    }
    .search-bar-box input{
        padding: 15px 50px 15px 30px;
    }
    .search-bar-box img {
        top: 15px;
        right: 15px;
    }
    .search-bar .nav-tabs .nav-link {
        border-radius: 6px;
    }
    .top-part{
        text-align: center;
        display: none;
    }
    .mobile-inner-menu{
        display: block;
    }
    .mobile-inner-menu {
        margin: 10px 0 10px 0;
        border-top: 1px solid #fff;
        padding: 10px 0px 0px 10px;
        width: 100%;
    }
     .mobile-inner-menu li a{
        font-size: 13px;
        font-weight: 400;
        text-decoration: none;
        color: #fff;
        margin-top: 10px;
        display: block;
     }
  .mobile-inner-menu li    {
    list-style: none;
  }
    .top-select-pagination {
        display: grid;
        gap: 20px;
    }
    .page-body{
        padding: 30px 10px 60px 10px;
    }
    .top-part ul li{
        margin: 0 15px;
    }
    .bill-body-text-btn-sec {
        display: grid;
        gap: 15px;
    }
    .bill-body-text {
        padding: 20px 15px;
    }
    .bill-body-text-like-inner-sec ul li {
        display: inline-block;
        margin: 0 20px 0 0px;
    }
    .sponcer-text-sec .row{
        gap: 10px;
    }
    .right-bar-heading h3 {
        font-size: 18px;
    }
    .blue-btn{
        font-size: 14px;
    }
    .navigation .navbar-toggler:focus{
        box-shadow: none;
    }
    app-footer{
      position:initial;
    }
    .copy-right {
        padding: 20px 0 ;
        background-color: #ced4e1;
    }
    .resp-page{
        margin: 0px 0 30px 0;
    }
    .sponcer-text-sec-inner.br-1{
        border-right: none;
    }
    .breadcrumb .container.px-5{
        padding-right: var(--bs-gutter-x,.75rem) !important;
    padding-left: var(--bs-gutter-x,.75rem) !important;
    }
     .search-bar .container.px-5{
        padding-right: var(--bs-gutter-x,.75rem) !important;
    padding-left: var(--bs-gutter-x,.75rem) !important;
    }
        /* upcoming-event */
        .timeline-box ul li::after{
            left: 0px;
        }
        .timeline-box ul li:last-child:after{
            display: block;
        }
        .event-tab {
            margin: 30px 0 30px 0;
        }
        .chart-box{
            text-align: center;
        }
        .modal-button{
            width: 100% !important;
            padding: 18px 70px;
            font-size: 14px;
        }
        .event-top-btns {
            display: grid;
            gap: 20px;
        }
        .timeline-box ul li {
            display: block;
            margin: 15px 0;
            width: 100%;
        }
        .event-tab .nav .nav-item button{
            font-size: 14px;
        }

      .bill-filter.event-tab .nav .nav-item button{
          font-size: 12px;
      }

      .bill-filter.event-tab .nav .nav-item button.active{
        border-radius: 6px 6px 0 0;
    }
        .event-tab .nav .nav-item button.active{
            border-radius: 6px;
        }
        .event-tab table tbody tr td{
            vertical-align: baseline;
            width: 100%;
        }
        .event-tab table tbody tr{
            display: grid;
        }
        .event-tab table tbody tr td.text-end{
            text-align: left !important;
        }
        .vote-modal .red-btn {
            width: 100%;
        }
        /* survey */
        .survey-body h3 {
            font-size: 18px;
        }
        .sb-date {
            font-size: 11px;
        }
        .survey-body p {
            font-size: 12px;
        }
        .red-btn-two {
            padding: 8px 20px;
            font-size: 12px;
        }
        /* surver-details */
        .h-100vh-blank-page .copy-right {
            position: inherit;
        }
        .h-100vh-blank-page {
            height: 100%;
        }
        .surver-vector {
            margin-bottom: 30px;
        }
        .surver-vector ul li {
            margin: 0px 30px;
        }
        .red-heading {
            font-size: 20px;
        }
        /* surver-ratings */
        .order-xs-1{
            order: 1;
        }
        .order-xs-2{
            order: 2;
        }
        .survey-details-img{
            text-align: center;
            margin-bottom: 60px;
        }
        .brd-sc.px-5{
            padding-right: var(--bs-gutter-x,.75rem) !important;
            padding-left: var(--bs-gutter-x,.75rem) !important;
        }
        .form-group label {
            font-size: 14px;
        }
        .survey-details-rating .mt-60{
            margin-top: 30px;
        }
        .rating-starts li img{
            max-width: 80%;
        }
    /* Report-issue-listing */
    .issue-check-and-btn{
        display: block;
    }
    .issue-check-and-btn .red-btn{
    margin: 20px auto 0 0;
    font-size: 14px;
    }
    .pt-resp-0{
        padding-top: 0;
    }
    .blue-radio-btn label {
        font-size: 15px;
    }
     /* Report-issue-create */
     .breadcrumb ul li a {
        font-size: 12px;
    }
    .blue-heading {
        font-size: 18px;
    }
    .Enquiry-box h3 {
        padding-bottom: 12px;
    }
    .drag-drop-box{
        height: auto;
    }
    .drag-drop-box h4 {
        font-size: 16px;
    }
    .drag-drop-box button {
        padding: 20px 20px;
        font-size: 14px;
    }
    .uploading-file::before {
        width: 60%;
    }
    .uploading-file span {
        width: 60%;
    }
    .Enquiry-box .red-btn {
        font-size: 18px;
        width: auto !important;
    }
      /* logged-issue */
      .project-table table thead tr th{
        white-space: nowrap;
    }
    .project-table table tbody tr td{
        white-space: nowrap;
    }
     /* Information-requested */
     .request-body-content-inner-two h5{
        line-height: 20px;
    }
    .request-tab .container.px-5{
        padding-right: var(--bs-gutter-x,.75rem) !important;
        padding-left: var(--bs-gutter-x,.75rem) !important;
    }
    .request-body-sec {
        margin-top: 0;
    }
    .request-tab .bd-example{
        margin-bottom: 15px;
    }
     /* member */
     .member-name-three {
        padding: 30px 30px;
    }
    .member-name-three .br-one{
        border-right: none;
    }
     .member-name-three .member-info {
        margin-bottom: 15px;
     }
     .key-staff-body h4 {
        font-size: 15px;
    }
    .key-staff-body span {
        font-size: 12px;
    }
    .key-staff .row{
        gap: 30px 0px;
    }
    .member-name-one ul li {
        padding: 12px 14px;
        font-size: 12px;
        margin-right: 0;
    }
    .short-red-btn {
        font-size: 12px;
    }
    .member-info {
        justify-content: left;
    }
    .member-name-one div{
        width: auto !important;
        white-space: nowrap;

    }
 .member-name-one{
    overflow-x: auto;
        overflow-y: hidden ;
 }
    .member-name-one div:last-child{
        display: contents;
    }
        /* about-us */
        .about-heading {
            font-size: 30px;
        }
        .about-heading-sub {
            font-size: 15px;
        }
        .state-carousal .owl-nav .owl-prev span{
            top: -70px;
            left: 20px;
        }
         .state-carousal .owl-nav .owl-next span{
            top: -70px;
            right: -20px;
        }
        .carou-content h5 {
            font-size: 16px;
        }
        .banner h1 {
            font-size: 30px;
        }
        .banner p {
            font-size: 15px;
        }
        .state-text h2 {
            font-size: 25px;
        }
        .state-text p {
            font-size: 14px;
        }
        /* contact */
        .contact-sec {
            padding: 20px 20px;
        }
        .blk-heading {
            font-size: 25px;
        }
        .contact-sec p {
            font-size: 14px;
        }
        .input-wrapper label {
            font-size: 14px;
        }
        .banner{
            padding: 100px 0 100px 0;
        }
           /* index */
    .banner h1 {
        font-size: 30px;
    }
    .index-card-head {
        font-size: 25px;
        line-height: 35px;
    }
    .index-inner-card{
        margin-bottom: 30px;
        min-height: auto;
    }
    .index-cont .about-heading-sec{
        text-align: center !important;
    }
    .px-60 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .index-card {
        margin: 0 0 0px 0;
    }
    .navigation .home-nav-btn a{
        margin-top: 10px;
    }
    .index-inner-card h3 {
        font-size: 18px;
    }
    .mob-reso-non{
        display: none;
    }
    .resp-none{
        display: block;
        height: auto;
    }
    .desk-none{
        display: none !important;
        height: auto;
    }
    .constituent-sign-up .about-para{
        text-align: center;
    }
    .otp-two{
        padding-top: 15px;
    }
       /* singup-two */
       .singup-two{
        padding-top: 0;
    }
    .view-inner-img img{
        margin-bottom: 30px;
    }
    /* report-details */
    .report-details-page button.light-blue-btn{
        margin-bottom: 15px;
        margin-right: 0 !important;
        width: 100%;
    }

    .comment-sec{
        height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .reply-date {
        margin-bottom: 15px;
        display: inline-block;
    }
    .Enquiry-box{
        margin-bottom: 15px;
    }
    .reply-drop{
        display: inline-block;
        float: right;
    }
    .top-search-bill .row{
      gap: 1rem;

    }
    .top-search-bill select{
      padding: 15px 15px;
    }
    .top-search-bill .red-btn{
      width: 100%;
      padding: 15px 15px;
    }
    .event-tab table tbody tr td label{
      white-space: nowrap;
    }
    .breadcrumb ul li a.active {
      width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
      padding-top: 6px;

    }
}
