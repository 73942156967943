body{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: 'Libre Baskerville', serif;
}
*{
  font-family: 'Libre Baskerville', serif;
}
img{
    max-width: 100%;
}
:root{
    --text-color:#181818;
    --gray-color:#A8A8A8;
    --btn-color:#12457C;
    --text-sub-color:#8F8F8F;
    --red-color:#A60606;
    --paragraph-color:#484848;
    --black-btn:#000;
    --green-color:#3FB915;
}

.text-small {
    font-size: 0.85em;
}

/* login */
.login-background{
    background: url(/background-img.jpg) top center no-repeat;
    background-size: cover;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
.login-page{
    padding: 30px;
    background-color: #F8F8F8;
    box-shadow: -4px 0px 20px 0px rgba(18, 69, 124, 15%);
    height: 100%;
}
.login-icon a img{
    max-width: 100%;
}
.login-icon {
    margin: 0 0 20px 0;
    text-align: center;
}
.login-radio-tab {
    padding: 18px 0;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    display: flex;
    align-items: center;
}
.login-radio-tab .form-check label{
    font-size: 17px;
    font-weight: 700;
    color: #676767;
    cursor: pointer;
}
.login-radio-tab .form-check input{
    width: 30px;
    height: 30px;
    border-color: #DFDFDF;
    margin-top: 0;
    margin-right: 15px;
}
.login-radio-tab .form-check-input:checked[type=radio]{
    background: url(../img/Ellipse.png) center center no-repeat;
    border-color: #DFDFDF;
}
.login-radio-tab .form-check {
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.login-radio-tab .form-check input:focus{
    box-shadow: none;
}
.login-radio-tab .form-check.active label{
    color: var(--text-color);
}
.login-content{
    padding: 30px 0 0 0;
}
.project-heading{
    font-weight: 700;
    color: var(--text-color);
    margin: 0 0 6px 0;
    font-size: 32px;
    line-height: 45px;
}
.project-subheading{
    color: var(--text-sub-color);
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}
.mb-18{
    margin-bottom: 18px;
}
.mb-15{
    margin-bottom: 15px;
}
.project-input {
    padding: 15px;
    border-radius: 8px !important;
    box-shadow: 2px 0px 4px 0px rgba(235, 240, 244, 99%);
    background-color: #fff;
    font-weight: 700;
    font-size: 16px;
    color: var(--text-color);
    border: none;
    border-color: transparent !important;
}
.pr-from .project-input{
     padding: 15px 50px 15px 15px;
}
.project-form .project-input{
    padding: 15px;
    border-radius: 8px;
    box-shadow: 2px 0px 4px 0px rgba(235, 240, 244, 99%);
    /* background-color: #fff; */
    font-weight: 700;
    font-size: 16px;
    color: var(--text-color);
    border: none;
    font-family: 'Libre Baskerville', serif;
}
.pr-from{
    position: relative;
}
.pr-from img{
    position: absolute;
    top: 15px;
    right: 15px;
}
.project-input::placeholder{
    font-weight: 700;
    font-size: 16px;
    color: var(--gray-color);
}
.project-input:focus{
    border: none;
    box-shadow: 2px 0px 4px 0px rgba(235, 240, 244, 99%);
}
.forget-sec{
    padding: 10px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.forget-sec a{
    color: #3E3E3E;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}
.project-btn{
    padding: 20px;
    background-color: var(--btn-color);
    color: #fff;
    font-weight: 700;
    border-radius: 8px;
    border: none;
    width: 100%;
}
.bill-filter .project-btn {
    border-radius: 6px;
}
.anchor-text{
    color: #3E3E3E;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}
.anchor-text:hover{
    color: #3E3E3E;
}
.constituent-login{
    padding: 50px 30px;
    background-color: #12457C;
    text-align: center;
    height: 100%;
}
.constituent-login h1{
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 38px 0;
    color: #fff;
}
.constituent-login p{
    font-weight: 400;
    font-size: 15px;
    margin: 0 40px;
    color: #fff;
}
.constituent-login h3{
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    margin: 0;
}
/* constituent-sign-up */
.constituent-sign-up{
    padding: 30px 30px 50px 30px;
    background-color: #F8F8F8;
    box-shadow: -4px 0px 20px 0px rgba(18, 69, 124, 15%);
}
.click-login{
    display: flex;
    align-items: center;
}
.click-login img{
    margin-right: 5px;
}
.click-login p{
    color: #3E3E3E;
    font-size: 15px;
    font-weight: 700;
    margin: 0;
}
.click-login p a{
    color: #A60606;
    margin: 0;
    text-decoration: none;
}
.mb-58{
    margin-bottom: 58px;
}
.constituent-sign-up-img{
    text-align: right;
    height: 100%;
}
.gap-30{
    gap: 30px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-25{
    margin-bottom: 25px;
}
.bd-example{
    position: relative;
     background-color: #fff;
     border-radius: 14px;
}
.bd-example .project-input{
    position: relative;
    z-index: 1;
    /* background: transparent; */
}
.bd-example::after{
    background: url(/Expand-Arrow.png) top right no-repeat;
    content: "";
    right: 10px;
    top: 15px;
    width: 24px;
    height: 24px;
    position: absolute;
    z-index: 0;
}
.bd-example span{
    position: absolute;
    font-weight: 700;
    font-size: 14px;
    color: #A8A8A8;
    position: absolute;
    left: 30px;
    top: 10px;
    z-index: 1;
}
.bd-example span sup{
    font-weight: 700;
    color: var(--red-color);
    font-size: 15px;
    position: absolute;
    top: 8px;
}
.bd-example .project-input {
    padding: 35px 60px 15px 30px;
}
.mb-24{
    margin-bottom: 24px;
}
.language-pick .project-input{
    padding: 25px 60px 25px 100px;
}
.language-pick{
    position: relative;
}
.language-pick .language-icon{
    position: absolute;
    top: 25px;
    left: 25px;
    border-right: 1px solid #E8E8E8;
    padding-right: 35px;
}
.language-pick .language-icon::after{
    position: absolute;
    content: "";
    background: url(/Expand-Arrow.png) top right no-repeat;
    right: 5px;
    top: 0;
    width: 24px;
    height: 24px;
}
.green-radio-btn.form-check{
    position: absolute;
    right: 25px;
    top: 22px;
    margin: 0;
}
.green-radio-btn.form-check input{
    width: 24px;
    height: 24px;
}
.green-radio-btn .form-check-input:checked {
    background-color: #62d639 !important;
    border-color: #62d639;
}
.green-radio-btn .form-check-input:checked[type=radio]{
    background: url(/tick.svg) center center no-repeat;
    background-color: #62d639 !important;
}
.p-24{
    padding: 24px;
}
.desk-mb-0 .mb-24{
    margin-bottom: 0;
}

.input-wrapper {
    position: relative;
    line-height: 14px;
    width: 100%;
    display: inline-block;
  }
  .input-wrapper label {
    font-weight: 700;
    font-size: 14px;
    color: var(--gray-color);
    position: absolute;
    z-index: 2;
    left: 20px;
    top: 30px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 100ms ease;
    -moz-transition: -moz-transform 100ms ease;
    -o-transition: -o-transform 100ms ease;
    -ms-transition: -ms-transform 100ms ease;
    transition: transform 100ms ease;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  .input-wrapper label sup{
    font-weight: 700;
    color: var(--red-color);
  }
  .input-wrapper input,
  .input-wrapper textarea,
  .input-wrapper select {
    position: relative;
    padding: 25px 15px 5px 15px;
  }
  .project-form.input-wrapper select.project-input {
    padding: 15px;
    background-color: #FFF !important;
  }
  /* .input-wrapper input:invalid + label  {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .input-wrapper input.not-require + label {

    -webkit-transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
    -o-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;

  }
  .input-wrapper input.not-require:focus + label{
    -webkit-transform: translateY(-20px) !important;
    -moz-transform: translateY(-20px) !important;
    -o-transform: translateY(-20px) !important;
    -ms-transform: translateY(-20px) !important;
    transform: translateY(-20px) !important;

  }
  .input-wrapper input:valid + label{
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);

  }
  .input-wrapper input:focus + label {
    font-weight: 700;
    font-size: 14px;
    color: var(--gray-color);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  .input-wrapper textarea:invalid + label {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .input-wrapper textarea:focus + label {
    font-weight: 700;
    font-size: 14px;
    color: var(--gray-color);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  } */

  /* bill */
  .top-part{
    background-color: #042A53;
    padding: 15px;
    text-align: right;
  }
  .top-part ul{
    padding: 0;
    margin: 0;
  }
  .top-part ul li{
    display: inline-block;
    margin-left: 20px;
  }
  .top-part ul li a{
        font-size: 13px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    text-transform: capitalize;
  }
  .navigation .navbar-brand{
    margin-top: -62px;
    background-color: #F1F1F1;
    position: relative;
    z-index: 1;
    padding: 15px 0;
  }
  .navigation .navbar{
    background-color: var(--btn-color);
    padding: 0;
  }
.navigation{
    position: sticky;
    top: 0;
    z-index: 100;
}
/* .navigation::after{
    position: absolute;
    background-color: #F1F1F1;
    content: "";
    top: 0;
    left: 0;
    width: 25%;
    height: 100%;
    z-index: 0;
} */
.navigation .nav-item a{
    color: #fff !important;
    font-weight: 700;
    font-size: 15px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
}
.navigation .nav-item a img{
    margin-right: 10px;
}
.navigation .nav-item.user-profile a{
    margin-left: 70px;
    padding: 0;
}
.navigation .nav-item.user-profile a::after{
    border: 0;
    background: url(/assets/img/Collapse-Arrow.png) bottom right no-repeat;
    width: 24px;
    height: 24px;
}
.navigation .user-profile .dropdown-menu li a{
    margin-left: 15px !important;
    color: var(--text-color) !important;
}
.navigation .user-profile .dropdown-menu li a:hover{
    background-color: transparent;
}
.navigation .user-profile .dropdown-menu {
    right: 0;
    left: auto;
}
.breadcrumb{
    padding: 15px 0;
    text-align: left;
}
.breadcrumb ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
.breadcrumb ul li{
    padding: 0;
    margin: 0 10px 0 0;
    list-style: none;
    display: inline-block;
}
.breadcrumb ul li a img{
    margin-right: 10px;
}
.breadcrumb ul li a {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
}
.breadcrumb ul li a.active{
    color: var(--red-color);
}
.search-bar{
    padding: 20px 0;
    background-color: #F2F5F8;
}
.search-bar-box input{
    border-radius: 14px;
    box-shadow: 2px 0px 4px 0px rgba(235, 240, 244, 99%);
    background-color: #fff;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-color);
    border: 1px solid var(--btn-color);
    padding: 20px 60px 20px 30px;
}
.bill-filter .search-bar-box input {
    border-radius: 6px;
}
.search-bar-box{
    position: relative;
}
.search-bar-box img{
    position: absolute;
    top: 20px;
    right: 25px;
}
.search-bar-box input::placeholder{
    color: #BCBCBC;
    font-size: 14px;
    font-weight: 700;
}

.page-body{
    background: url(/background-img.jpg) top center no-repeat;
    background-size: cover;
    position: relative;
    padding: 30px 30px 80px 30px;
    z-index: 0;
}
.page-body-overlay{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.top-select-pagination{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.select-box-all{
    display: flex;
    align-items: center;
}
.select-box {
    text-align: left;
    margin-right: 17px;
}
.select-box label{
    font-size: 13px;
    font-weight: 700;
    color: #000;
    margin: 0 0 8px 0;
    display: block;
}
.select-box .form-select{
    border: 1px solid #DDDDDD;
    padding: 12px 35px 12px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #3c3c3c;
}
.form-select:focus{
    box-shadow: none;
}
.form-control:focus{
    border-color: initial;
    box-shadow: none;
}
.form-control:focus,
form button:focus {
    outline: 3px solid #feb272 !important;
}
.pagination-sec ul {
    padding: 0;
    margin: 0;
}
.pagination-sec ul li{
    list-style: none;
    display: inline-block;
    padding: 7px 14px;
    font-weight: 700;
    font-size: 13px;
    color: #000;
    border-radius: 5px;
    line-height: 20px;
    cursor: pointer;
}
.pagination-sec .pag-arrow i{
    font-size: 18px;
    font-weight: bold;
}

.pagination-sec ul li.pag-arrow-active i{
    color: #fff;
    opacity: .5;
}
.pagination-sec ul li.pag-arrow{
    border: 1px solid #12457C;
    background-color: #fff;
}
.pagination-sec ul li.pag-num-active{
    border: 1px solid #12457C;
    background-color: #fff;
}
.pagination-sec ul li.pag-arrow-active{
  background-color: #fff;
  border: 1px solid #a60606;
  margin: 0 5px;
}
.bill-body-text{
    padding: 25px 30px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(233, 233, 243, 99%);
    border-radius: 12px;
    border: 1px solid #EFEFEF;
    transition: 0.3s ease-in-out;
}
.bill-body-text:hover{
    border: 1px solid var(--btn-color);
    transition: 0.3s ease-in-out;
    transform: scale(1.01);
}
.bill-body-text:hover .inner-red-btn{
    background-color: var(--red-color);
    color: #fff;
    border: 1px solid var(--red-color);
    transition: 0.3s ease-in-out;
}
.bill-body-text-btn-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.bill-body-text-btn-inner-sec{
    display: flex;
    align-items: center;
}
.inner-red-btn {
    font-size: 13px;
    font-weight: 700;
    border: 1px solid var(--red-color);
    color: var(--red-color);
    padding: 10px 15px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    background-color: #fff;
}
.inner-red-btn:hover{
    background-color: var(--red-color);
    color: #fff;
     border: 1px solid var(--red-color);
     transition: 0.3s ease-in-out;
}
.bill-body-text-btn-inner-sec p{
    font-weight: 400;
    font-size: 13px;
    color: var(--btn-color);
    margin: 0;
}
.bill-body-text-btn-inner-sec p strong{
    color: var(--red-color);
}
.bill-body-text-like-inner-sec ul{
    margin: 0;
    padding: 0;
}
.bill-body-text-like-inner-sec ul li{
    display: inline-block;
    margin: 0 0 0 24px;
    list-style: none;
    font-weight: 400;
    font-size: 13px;
    color: #000;
    line-height: 36px;
}
.bill-body-text p{
    font-size: 14px;
    font-weight: 400;
    color: var(--paragraph-color);
    margin: 0;
}
.bill-body-text-like-inner-sec ul li img{
    cursor: pointer;
}
.sponcer-text-sec{
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;
    border-top: 1px solid #E0E8F1;
}
.sponcer-text-sec-inner label{
    font-size: 13px;
    font-weight: 700;
    color: #8C99A7;
    margin-bottom: 3px;
}
.sponcer-text-sec-inner p{
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    color: var(--btn-color);
}
.br-1{
    border-right: 1px solid #E0E8F1;
}
.right-bar-heading{
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cbcbcb;
}
.right-bar-heading img{
    margin-right: 6px;
}
.right-bar-heading h3{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0;
}
.right-gray-box{
    padding: 18px 14px;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    text-align: center;
}
.right-gray-box h1{
    font-weight: 700;
    font-size: 15px;
    margin: 0 0 12px 0;
    color: #000;
    text-transform: capitalize;
}
.right-gray-box p{
    color: #3D3D3D;
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 10px 0;
}
.red-btn{
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    padding: 12px 30px;
    border-radius: 12px;
    background-color: var(--red-color);
    display: block;
    border: none;
    margin: 0 auto;
}
.bill-filter .red-btn {
    border-radius: 6px;
}
.view-text{
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin: 7px 0 0 0;
    display: block;
}
.view-text:hover{
    color: #000;
}
.project-right-bar-carousal .carousel-item{
    text-align: center;
}
.project-right-bar-carousal .carousel{
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(147, 147, 147, 25%);
    border-radius: 10px;
    padding: 15px 0;
}
.project-right-bar-carousal .carousel-item img{
    height: 262px;
}
.project-right-bar-carousal .carousel-control-prev-icon{
    background: url(/Expand-Arrow-2.png) center center no-repeat;
}
.project-right-bar-carousal .carousel-control-next-icon{
    background: url(/Expand-Arrow-3.png) center center no-repeat;
}
.project-right-bar-carousal .carousal-content{
    margin: 10px 0 0 0;
}
.project-right-bar-carousal .carousal-content h4{
    font-weight: 700;
    font-size: 20px;
    color: #000;
    margin: 0 0 5px 0;
}
.project-right-bar-carousal .carousal-content p{
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    margin: 0;
}
.blue-btn {
    padding: 15px 14px;
    background-color: var(--btn-color);
    border-radius: 12px;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    border: none;
}
.blue-btn svg{
    margin-right: 8px;
}
.right-bar-blue-btn{
    margin: 15px 0 0 0;
}
.blue-btn-arrow{
    position: relative;
}
.blue-btn-arrow::after{
    background: url(/right-btn-arrow.png) center right no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    content: "";
    right: 14px;
}
.copy-right{
    text-align: center;
    /* padding: 40px 0 0 0; */
}
.copy-right span{
    font-weight: 400;
    font-size: 13px;
    color: #000;
}
/* upcoming-events */
.breath-line-btm{
    margin-bottom: 30px;
    border-bottom: 1px solid #CFCFCF;
}
.light-blue-btn{
    font-size: 13px;
    font-weight: 700;
    color: var(--btn-color);
    background-color: #F4F9FE;
    border: 1px solid var(--btn-color);
    border-radius: 9px;
    padding: 14px 20px;
}
.light-white-btn{
       font-size: 13px;
    font-weight: 700;
    color: var(--btn-color);
    background-color: #fff;
    border: 1px solid #CACACA;
    border-radius: 9px;
    padding: 12px 15px;
    transition: 0.3s ease-in-out;
}
.light-white-btn:hover{
    border: 1px solid var(--red-color);
    background-color: var(--red-color);
    transition: 0.3s ease-in-out;
}
.light-white-btn:hover .non-hover-voice{
    display: none;
}
.light-white-btn:hover .hover-voice{
    display: block;
}

.light-white-btn .hover-voice{
    display: none;
}

.dark-black-btn{
     font-size: 13px;
    font-weight: 700;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 9px;
    padding: 12px 20px;
    color: #fff;
    transition: 0.3s ease-in-out;
}
.dark-black-btn:hover{
    transition: 0.3s ease-in-out;
     background-color: var(--red-color);
    border: 1px solid var(--red-color);
}
.event-top-btns{
    display: flex;
    justify-content: space-between;
    margin: 0 0 25px 0;
}
.event-para-btn p{
    color: #1B1B1B;
    font-weight: 700;
    font-size: 14px;
    margin: 0;
}
.status-text-inner .bill-body-text-btn-inner-sec p{
    font-size: 16px;
}
.status-text-inner{
    margin: 40px 0 0 0;
}
.timeline-box{
    padding: 16px 8px;
    background-color: #FAFCFF;
    border: 1px solid #D7E4F2;
    border-radius: 12px;
    text-align: center;
    margin: 0 0 20px 0;
}
.timeline-box ul{
    padding: 0;
    margin: 0;
}
.timeline-box ul li{
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 15px;
    font-weight: 400;
    /* color: var(--btn-color); */
    color: #424242;
    text-align: center;
    width: 19%;
    position: relative;
}
.timeline-box ul li span{
    width: 10px;
    height: 10px;
    /* background-color: var(--btn-color); */
    background-color:#424242;
    border-radius: 50px;
    display: block;
    margin: 0 auto 8px auto;
}
.timeline-box ul li::after{
    position: absolute;
    content: "";
    height: 1px;
    background-image: linear-gradient(to right, #424242 10%, rgba(255, 255, 255, 0) 0%);
    background-size: 9px 1px;
    background-size: 6px 1px;
    top: 5px;
    left: 73px;
    width: 100%;
}
.timeline-box ul li:last-child::after{
    display: none;
}
.timeline-box ul li.active span{
    background-color: var(--red-color);
}
.timeline-box ul li.active {
    color: var(--red-color);
}

.timeline-box ul li.timelinepending span{
  background-color: var(--btn-color);
}
.timeline-box ul li.timelinepending {
  color: var(--btn-color);
}

.timeline-box ul li.timelinepending::after{
  background-color: #12457c;

}
/* .timeline-box ul li.active::after {
    background-image: linear-gradient(to right, #A60606 10%, rgba(255, 255, 255, 0) 0%);
} */
.status-text-inner p{
    font-size: 13px;
    font-weight: 400;
    color: var(--paragraph-color);
    margin: 0;
}
.status-text-inner p strong{
    color: var(--red-color);
    cursor: pointer;
}
.event-tab {
    margin: 70px 0 0 0;
}
.event-tab .nav-tabs{
    justify-content: center;
}
.event-tab .tab-pane{
    border: 1px solid var(--btn-color);
    border-radius: 12px;
    padding: 15px;
    background-color: #fff;
}

.event-tab .tab-pane table{
    overflow-y: auto;
    height: 250px;
    display: block;
}
.event-tab .tab-pane table::-webkit-scrollbar{
    width: 8px;
    margin-left: 5px;
    position: absolute;
    right: 5px;
}
.event-tab .tab-pane table::-webkit-scrollbar-track{
    background: #D8E1EA;
}
.event-tab .tab-pane table::-webkit-scrollbar-thumb{
    background: #12457C;
}

.event-tab .nav .nav-item button.active{
    padding: 10px 18px;
    border-radius: 10px 10px 0 0;
    background-color: var(--btn-color);
    color: #fff;
    border-bottom: 1px solid var(--btn-color);
}
.event-tab .nav .nav-item button {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    padding: 10px 18px;
}
.event-tab .nav .nav-item button:hover{
    border-color: transparent;
}
.event-tab table{
    width: 100%;
    display: block;
}
.event-tab table tbody{
    width: 100%;
    display: block;
}
.event-tab table tbody tr{
    border-bottom: 1px solid #E9E9E9;
    box-shadow: 0px 4px 4px 0px rgba(157, 157, 157, 10%);
    width: 100%;
    display: table;
}
.event-tab table tbody tr td{
    font-weight: 400;
    font-size: 14px;
    color: #1D1D1D;
    padding: 14px;
    vertical-align: bottom;
}
.light-blue-btn-inner{
    padding: 6px;
    border-radius: 4px;
    background-color: #F3F7FC;
    border: 1px solid #D5DDE5;
    color: #272727;
    font-size: 13px;
    font-weight: 400;
}
.event-tab table tbody tr td label{
    display: block;
    color:#5A6878;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}
.event-tab table tbody tr td span{
       display: block;
    color:var(--btn-color);
    font-size: 14px;
    font-weight: 400;
}
.w-40{
    width: 40%;
}
.light-blue-box{
    background-color: #F3F8FD;
    border: 1px solid #EFEFEF;
    border-radius: 12px;
    padding: 15px 15px;
}
.light-blue-box h3{
    font-size: 16px;
    font-weight: 700;
    color: var(--red-color);
    margin: 0 0 7px 0;
}
.light-blue-box p{
    font-size: 13px;
    color: #424242;
    margin: 0;
}
.light-blue-box p.bill-summary {
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    max-height: 20em;
    overflow: scroll;
    padding: 1em;
    white-space: pre-wrap;
}
.vote-sec{
    padding: 15px 50px;
    background-color: #F3F8FD;
    border: 1px solid var(--btn-color);
    text-align: center;
    border-radius: 12px;
}
.vote-sec h3{
    font-weight: 700;
    font-size: 15px;
    color: #000;
    margin-bottom: 10px;
}
/* modal */
.vote-modal .modal-content{
    background-color: #F8F8F8;
    border-radius: 12px;
    padding: 15px 20px;
    text-align: center;
}
.vote-modal .modal-content .modal-body p{
    font-weight: 400;
    font-size: 14px;
    color: #1B1B1B;
    margin: 20px 0 20px 0;
}
.modbody{
  background:#F8F8F8;
}
.modal-button{
    border: 1px solid #CFCFCF;
    padding: 15px 30px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 700;
    color: #444;
    border-radius: 50px;
    display: block;
    transition: 0.3s ease-in-out;
    max-width: 400px;
}
.modal-button:hover{
    background-color: var(--btn-color);
    border: 1px solid var(--btn-color);
    color: #fff;
    transition: 0.3s ease-in-out;
}
.modal-textarea{
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    color: #444;
    font-size: 16px;
    border: none;
    font-weight: 400;
}
.modal-textarea::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #7A8794;
}
.w-30{
    width: 30%;
}
.vote-modal .red-btn{
    padding: 16px 30px;
}
.navigation .user-profile .dropdown-menu li .dropdown-item{
    margin: 8px 10px !important;
    padding: 0;
    font-size: 14px;
}
.navigation .user-profile .dropdown-menu li .dropdown-item:active{
    background-color: transparent;
}
.mobile-inner-menu{
    display: none;
}
/* survey */
.survey-body{
    padding: 15px 25px;
    border-radius: 12px;
    border: 1px solid #BAD4EF;
    text-align: left;
    background-color: #fff;
    transition: 0.3s ease-in-out;
}
.sb-date{
    padding: 6px 8px;
    font-size: 13px;
    font-weight: 400;
    color: #202020;
    background-color: #ECF1F5;
    border-radius: 25px;
    display: inline-block;
}
.mb-10{
    margin-bottom: 10px !important;
}
.survey-body h3{
    font-weight: 700;
    font-size: 22px;
    color: var(--btn-color);
    text-transform: capitalize;
}
.survey-body p{
    font-size: 14px;
    font-weight: 400;
    color: #343434;
}
.red-btn-two{
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
    background-color: var(--red-color);
    color: #fff;
    transition: 0.3s ease-in-out;
    border: none;
    border: 2px solid var(--red-color);
}
/* .survey-body:hover{
    border: 1px solid var(--btn-color);
    transition: 0.3s ease-in-out;
    transform: scale(1.01);
}
.survey-body:hover .red-btn-two{
    border: 2px solid var(--red-color);
    background-color: #fff;
    color: var(--red-color);
    transition: 0.3s ease-in-out;
} */
.select-servey{
    border: 1px solid var(--btn-color);
}
.select-servey .red-btn-two{
    border: 2px solid var(--red-color);
    background-color: #fff;
    color: var(--red-color);
}
/* surver-details */
.select-line{
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.select-line li{
    display: inline-block;
    margin: 0 5px;
    font-weight: 700;
    font-size: 20px;
    color: #000;
}
.select-line li.select-line-inner{
    width: 43px;
    height: 2px;
    background-color: #D0E0F0;
}
.select-line li.select-line-inner.active{
    background-color: var(--red-color);
}
.survey-details-heading{
    text-align: center;
    margin: 36px 0 60px 0;
}
.red-heading{
    font-weight: 700;
    font-size: 24px;
    color: var(--red-color);
    text-transform: capitalize;
}
.gray-para{
    font-size: 14px;
    font-weight: 400;
    color: #4C5560;
    margin: 0;
}
.surver-vector{
    text-align: center;
}
.surver-vector ul{
    padding: 0;
    margin: 0;
}
.surver-vector ul li{
    display: inline-block;
    margin: 0 45px;
    font-size: 14px;
    color: #000;
    font-weight: 700;
}
.surver-vector ul li span{
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0px 2px 20px 0px rgba(222, 232, 242, 99%);
    display: block;
    line-height: 95px;
    margin-bottom: 16px;
}
.h-100vh-blank-page{
    height: 100vh;
}
.h-100vh-blank-page .copy-right {
    position: absolute;
    bottom: 25px;
    margin: 0 auto;
    left: 0;
    right: 0;
}
/* survey-ratings */
.rating-starts{
    padding: 0;
    margin: 0;
}
.rating-starts li{
    display: inline-block;
    margin-right: 8px;
}
.form-group label{
    font-weight: 700;
    font-size: 16px;
    color: #454E58;
    margin: 0 0 8px 0;
}
.surver-textarea{
    border: 1px solid #D0DBE7;
    font-size: 15px;
    border-radius: 24px;
    background-color: #fff;
}
.mt-60{
    margin-top: 60px;
}
/* report-issue-list */
.issue-check-and-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blue-radio-btn .form-check-input{
    width: 22px;
    height: 22px;
    background-color: #F0F7FE;
    border: 1px solid var(--btn-color);
}
.blue-radio-btn .form-check-input:checked {
    background-color: var(--btn-color);
    border-color: var(--btn-color);
}
.blue-radio-btn label{
    color: #000000;
    font-size: 17px;
    font-weight: 400;
    margin-left: 7px;
    margin-top: 2px;
}
.issue-check-and-btn .red-btn{
    padding: 18px 30px;
    text-decoration: none;
}
.issue-check-and-btn a{
   text-decoration: none;
}
.sticker-sec{
    display: flex;
    justify-content: space-between;
}
.badge{
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 13px;
}
.badge-green{
    color: var(--green-color);
}
.badge-red{
    color: var(--red-color);
}
.inneer-sub-hed{
    color: #000;
    font-weight: 700;
    font-size: 14px;
}
.inneer-sub-hed span{
    color: #87929E;
    font-weight: 400;
    font-size: 14px;
}
/* report-issue-create */
.Enquiry-box{
    background-color: #F8F8F8;
    border: 1px solid #BAD4EF;
    border-radius: 12px;
    padding: 20px 20px;
}
.blue-heading{
    font-size: 22px;
    font-weight: 700;
    color: var(--btn-color);
    margin: 0;
}
#official-heading {
    margin-bottom: 1em;
}
.Enquiry-box h3{
    padding-bottom: 14px;
    margin-bottom: 18px;
    border-bottom: 1px solid #E0E0E0;
}
.drag-drop-box{
    padding: 15px;
    border-radius: 14px;
    border: 1px dotted var(--btn-color);
    background-color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    height: 235px;
    width: 100%;
    justify-content: center;
}
.drag-drop-box h4{
    font-weight: 400;
    font-size: 20px;
    margin: 0 0 17px 0;
}
.drag-drop-box button{
    width: auto;
    padding: 20px 50px;
}
.drag-drop-box button img{
    margin-right: 10px;
}
.uploading-file{
    background-color: #fff;
    border: 1px solid #D0DCE8;
    padding: 10px 10px;
    height: 82px;
    position: relative;
    border-radius: 8px;
    z-index: 0;
}
.uploading-file label{
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0 0 8px 0;
    display: block;
}
.uploading-file span{


    display: block;
    height: 62px;
    overflow: hidden;

}

.uploading-file span img{


  width: 100px;
  border: 1px solid;
  padding: 2px;
  margin-right: 5px;
  max-height: 60px;
  object-fit: cover

}

.uploading-file span b{
    margin-left: 40px;
}
.uploading-file::before{
  display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 27%;
    height: 100%;
    background-color: #E7F0FB;
    content: "";
    z-index: -1;
    border-radius: 20px 0 0 20px;
}
.uploading-file a{
    width: 24px;
    height: 24px;
    background-color: var(--red-color);
    position: absolute;
    right: -5px;
    color: #fff;
    text-align: center;
    top: -5px;
    border-radius: 100px;
}
.uploading-file a svg{
    fill: #fff;
}
.Enquiry-box .red-btn{
    font-size: 24px;
}
/* loged Issue */
.project-table table thead tr th{
    padding:18px 30px;
    background-color: var(--btn-color);
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    line-height: 10px;
}


.project-table table thead tr th:nth-child(9){
    text-align: right;
}

.project-table table tbody tr td{
    padding: 14px 10px;
    color: #343434;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #E6E6E6;
}
.project-table table tbody tr {
    background-color: #fff;
    transition: 0.3s ease-in-out;
}
.project-table table tbody tr:hover {
    background-color: #F8FAFC;
    transition: 0.3s ease-in-out;
}


 /* .project-table table tbody tr td:first-child{
    border-left: 1px solid #BAD4EF;
}
.project-table table tbody tr td:last-child{
    border-right: 1px solid #BAD4EF;
} */

.project-table table tbody tr td:nth-child(9){
    text-align: right;
}
.project-table table tbody tr td:nth-child(8){
    text-align: left;
}
.project-table table thead tr th:nth-child(8){
    padding: 22px 20px;
    text-align: left;
}

/* .project-table table tbody tr{
    border-bottom: 1px solid #E6E6E6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
} */
.project-table table tbody tr:last-child td{
    border-bottom: none;
}
.project-table table{
    border-collapse: separate;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #BAD4EF;
}
.green-badge-btn{
    padding: 5px 20px;
    color: #068056;
    font-weight: 400;
    font-size: 14px;
    background-color: #D9FFF2;
    border-radius: 4px;
    border: none;
    width: 125px;
    cursor: default !important;
}
.red-badge-btn{
    padding: 5px 20px;
    color: #AA0D0D;
    font-weight: 400;
    font-size: 14px;
    background-color: #FDE2E2;
    border-radius: 4px;
    border: none;
    width: 125px;
    cursor: default !important;
}
.yellow-badge-btn{
    padding: 5px 20px;
    color: #B28B01;
    font-weight: 400;
    font-size: 14px;
    background-color: #FFF1E3;
    border-radius: 4px;
    border: none;
    width: 125px;
    cursor: default !important;
}
.blue-badge-btn{
    padding: 5px 20px;
    color: #1D74CA;
    font-weight: 400;
    font-size: 14px;
    background-color: #E7F3FF;
    border-radius: 4px;
    border: none;
    width: 125px;
    cursor: default !important;
}
.light-yellow-badge-btn{
    padding: 5px 20px;
    color: #B28B01;
    font-weight: 400;
    font-size: 14px;
    background-color: #FBF2D3;
    border-radius: 4px;
    border: none;
    width: 125px;
    cursor: default !important;
}
.top-part-two{
    padding: 0;
}
.top-part-two li{
    padding: 15px 0;
}
.top-part-two li.user-btn{
    background-color: var(--red-color);
    color: #fff;
    padding: 15px 20px;
    cursor: pointer;
}
.red-dot{
    fill: var(--red-color);
}
.gray-dot{
    fill: var(--gray-color);
}
.yellow-dot{
    fill: #E8C613;
}
/* Information requested */
.request-tab .nav-tabs{
    justify-content: center;
    border: none;
    border-top: 1px solid var(--btn-color);
    background-color: #F2F2F2;
}
.request-tab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    border-bottom: 3px solid var(--red-color);
    border-top: none;
    border-left: none;
    border-right: none;
    color: var(--btn-color);
    background-color: transparent;
}
.request-tab .nav-tabs button{
    padding: 15px 30px;
    font-weight: 700;
    font-size: 14px;
    border-top: none;
    border-left: none;
    border-right: none;
    line-height: 20px;
}
.request-tab .nav-tabs button:hover, .request-tab .nav-tabs button:focus, .request-tab .nav-tabs button:focus-visible{
    border-bottom: 3px solid var(--red-color);
    border-top: none;
    border-left: none;
    border-right: none;
}
.request-tab .nav-link{
    color: #7c7c7c;
}
.request-tab .form-control{
    border: 1px solid #DEDEDE;
    box-shadow: none;
}
.request-body-sec{
    z-index: 0;
    margin-top: -40px;
    padding-top: 60px;
}
.request-body-content{
    border-radius: 12px;
    border: 1px solid #BAD4EF;
    overflow: hidden;
}
.request-body-content-inner{
    padding: 20px 30px;
    background-color: #fff;
    transition: 0.3s ease-in-out;
}
.inner-blue-heading{
    font-size: 16px;
    font-weight: 700;
    color:#0B2B4E;
}
.request-tab{
    z-index: 1;
    position: relative;
}
.request-body-content-inner-two h5{
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: #7A8794;
}
.request-body-content-inner-two h5 span{
    color: #292F34;
}
.request-body-content-inner-two{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.request-body-devider{
    border-bottom: 1px solid #E6E6E6;
    transition: 0.3s ease-in-out;
}
.request-body-content-inner:hover{
    background-color: #F8FAFC;
    transition: 0.3s ease-in-out;
}
.cursor-pointer{
    cursor: pointer;
}
.request-select .project-input {
    padding: 28px 20px 10px 20px;
    color: #0B2B4E;
}
.request-select::after{
    top: 22px;
}
.request-tab .pr-from .project-input {
    padding: 19px 20px 19px 20px;
}
.request-tab .pr-from img{
    top: 22px;
}
.request-tab .bd-example span{
    font-weight: 400;
    font-size: 13px;
    color: #7A8794;
    left: 20px;
}
/* member */
.member-name{
    border: 1px solid #BAD4EF;
    border-radius: 12px;
    overflow: hidden;
}
.member-name-one{
    background-color: var(--btn-color);
    border-radius: 12px 12px 0 0;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
}
.member-name-one ul{
    padding: 0;
    margin: 0;
}
.member-name-one div{
    display: inline-block;
    padding: 20px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    width: 12%;
}
.member-name-two div:last-child{
    width: 5%;
}
.member-name-two {
    background-color: #fff;
    margin-top: -11px;
    height: 80px;
}
.member-name-two div{
    color: #343434;
}
.short-red-btn{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background-color: var(--red-color);
    border-radius: 4px;
    padding: 8px 14px;
    border: none;
}
.member-name-three{
    padding: 30px 80px;
    background-color: #F5F9FD;
    box-shadow: inset 0 4px 4px 0 #DFE9F3;
    margin-top: -11px;
    border-radius: 12px 12px 0 0;
}
.member-info{
    display: flex;
    justify-content: center;
}
.member-info-text h4{
    color: #000;
    font-size: 15px;
    font-weight: 700;
    margin: 0 0 0px 0;
}
.member-info-text span{
    font-size: 13px;
    font-weight: 400;
    color: #58697B;
}
.member-info-img{
    margin-right: 10px;
}
.br-one{
    border-right: 1px solid #C7C7C7;
}
.member-info h5{
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    color: #000;
}
.memb-bord-btm{
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DFDFDF;
}
.key-staff h3{
    font-size: 1.2em;
    font-weight: bold;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #E6E6E6;
}
.key-staff-body{
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 2px 0 #D3DAE1;
    margin-bottom: 25px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
}
.key-staff-body h4{
    font-weight: 700;
    font-size: 18px;
    color: #0D3B6C;
    margin: 0 0 2px 0;
}
.key-staff-body span{
    font-size: 13px;
    font-weight: 700;
    color: #88A4C2;
}
.key-staff-body img{
    margin: -15px -15px 0;
    max-width: none;
    width: calc(100% + 30px);
    aspect-ratio: 4 / 5;
    object-fit: cover;
}
.key-staff-body > div{
    padding: 15px;
}
.key-staff-body img{
    /* transition: 0.3s ease-in-out; */
    margin-bottom: 15px;
}
.key-staff-body img:hover{
    /* transform: scale(0.9);
    transition: 0.3s ease-in-out; */
}
.key-staff-body .affiliation {
    font-size: 0.9em;
}
.w-20{
    width: 20% !important;
}
.w-15{
    width: 15% !important;
}
.w-18{
    width: 18% !important;
}
.navigation .navbar-brand img{
    max-width: 160px;
    /* max-width: 80%; */
}
.navigation .navbar-brand {
    margin-right: 0;
}
/* about-us */
.page-black-btn{
    padding: 8px 16px 8px 40px;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    background-color: #000;
    border: none;
    text-align: left;
    position: relative;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
}
.page-black-btn span{
    font-size: 10px;
    display: block;
}
.page-black-btn img{
    margin-right: 8px;
}
.page-black-btn::after,
.page-red-btn::after{
    position: absolute;
    content: "";
    background: url(../img/white-Lock.png) center left no-repeat;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 10px;
    margin-top: -10px;
}
.page-black-btn:hover{
    background-color: var(--red-color);
    transition: 0.3s ease-in-out;
}

.page-red-btn{
    padding: 8px 16px 8px 40px;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    font-size: 13px;
    background-color: var(--red-color);
    border: none;
    text-align: left;
    position: relative;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
}
.page-red-btn span{
    font-size: 10px;
    display: block;
}
.page-red-btn img{
    margin-right: 8px;
}
.cms-page-navbar .nav-item  a{
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
}
.page-red-btn:hover{
    background-color: #000;
    transition: 0.3s ease-in-out;
}
.cms-page-navbar .navbar-brand{
    margin-top: 0;
    margin-right: 0;
}
.banner{
    background: url(../img/page-banner.png) top center no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    padding: 40px 0 28px 0;
    text-align: left;
    z-index: 0;
}
.banner .overlay-banner{
    position: absolute;
    background-color: rgba(0, 0, 0, 40%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
.banner h1{
    font-size: 40px;
    font-weight: 700;
    text-shadow: 3px 2px #000;
    margin-bottom: 15px;
    color: #fff;
}
.banner p{
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    text-shadow: 4px 2px #000;
}
.about-heading-sub{
    font-weight: 400;
    font-size: 20px;
    color: #212121;
    margin: 0 0 8px 0;
    text-transform: capitalize;
}
.about-heading-sec{
    margin: 20px 0 0 0;


}
.about-heading{
    font-size: 40px;
    font-weight: 700;
    color: var(--btn-color);
    margin: 0 0 20px 0;
    text-transform: capitalize;

}
.about-para{
    font-weight: 400;
    font-size: 14px;
    color: #000;
}
.ab-img{
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}
.state-text{
    margin: 35px 0 0 0;
}
.state-text h2{
    color: #000;
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 5px 0;
}
.state-text p{
    font-size: 16px ;
    font-weight: 400;
    color: #000;
    margin-bottom: 28px;
}
.state-carousal .item{
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 2px 0 #D3DAE1;
    margin-bottom: 5px;
}
.carou-content h5{
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 4px 0;
}
.carou-content {
    padding: 21px 22px;
    text-align: left;
       position: relative;
}
.carou-content i{
    position: absolute;
    right: 15px;
    top: 25px;
    color: #000;
    font-size: 25px;
}
.carou-content p{
    color: #585858;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.state-carousal .owl-dots{
    display: none;
}
.state-carousal .owl-nav .owl-prev span{
    font-size: 0;
    width: 48px;
    height: 48px;
    position: relative;
    display: block;
    text-align: center;
    border-radius: 50px;
    top: 0px;
    left: 0px;
}
.state-carousal .owl-nav .owl-prev span::before{
    position: absolute;
    content: "";
    font: normal normal normal 14px/1 FontAwesome;
    color: #000;
    left: 15px;
    top: 16px;
    background: url(/ab-carou-1.png);
    width: 24px;
    height: 24px;
}
.state-carousal .owl-nav .owl-next span{
    font-size: 0;
    width: 48px;
    height: 48px;
    position: relative;
    display: block;
    text-align: center;
    border-radius: 50px;
    bottom: 0;
    right: 0px;
}
.state-carousal .owl-nav {
    position: absolute;
    top: -90px;
    right: 0px;
}
.state-carousal .owl-nav .owl-next span::before{
    position: absolute;
    content: "";
    font: normal normal normal 14px/1 FontAwesome;
    color: #000;
    right: 15px;
    top: 16px;
    background: url(/ab-carou-2.png);
    width: 24px;
    height: 24px;
}

.about-heading-sec p{
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    /* text-transform: capitalize; */
}
.owl-carousel .owl-item .carou-content img {
    width: auto;
    position: absolute;
    right: 20px;
    top: 30px;
}
/* contact */
.contact-sec{
    padding: 20px 40px;
    background-color: #F8F8F8;
    margin-top: 17px;
}
.blk-heading{
    color: #000;
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 5px 0;
}
.contact-sec p{
    font-size: 15px;
    font-weight: 400;
    color: #8F8F8F;
    margin: 0 0 25px 0;
}
.cms-page-navbar .navbar-brand img{
    /* max-width: 75%; */
}
/* .cms-page-navbar.navigation::after{
    width: 25%;
} */
.mb-13{
    margin-bottom: 13px;
}
.contact-sec .project-input{
    box-shadow: 0px 2px 4px 0px rgba(235, 240, 244, 99%);
}
.contact-sec .input-wrapper label{
    font-weight: 400 !important;
}
.contact-sec .project-input{
    font-weight: 400;
}
.page-banner-two{
    background: url(../img/login-background-img.jpg) top center no-repeat;
    background-size: cover;
}
/* index */
.home-nav-btn a{
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
}
.index-banner {
    padding: 40px 0 28px 0;
    background-position: center center;
}
.index-card-head{
    font-weight: 700;
    font-size: 36px;
    color: #000;
    margin: 0 0 40px 0;
    text-align: center;
    text-transform: capitalize;
    line-height: 46px;
}
.index-inner-card{
    border-radius: 9px;
    border: 1px solid var(--btn-color);
    background-color: #fff;
    padding: 20px 15px;
    box-shadow: 0 2px 4px 0 #DFEBF8;
    min-height: 270px;
}
.index-inner-card h3{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0 0 15px 0px;
    display: flex;
    align-items: center;
}
.index-inner-card h3 img{
    margin-right: 5px;
}
.index-inner-card p{
    font-size: 13px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
    margin: 0 0 17px 0;
}
.index-inner-card .btn{
    font-size: 13px;
    font-weight: 400;
    background-color: var(--btn-color);
    border-radius: 6px;
    padding: 10px;
    color: #fff;
    border: none;
}
.index-card{
    margin: 0 0 50px 0;
}
.about-heading span{
    color: var(--red-color);
}
.read-more-btn{
    border-radius: 9px;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    padding: 10px 18px;
    background-color: var(--btn-color);
}
.read-more-btn:hover{
    color: #fff;
}
.mt-50{
    margin-top: 50px;
}
.px-60{
    padding-left: 60px;
    padding-right: 60px;
}
/* otp */
.otp-num{
    padding: 15px 0;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
}
.otp-num h5{
    text-align: center;
    font-weight: 700;
    color: #3E3E3E;
    margin: 0;
}
.otp-num h5 span{
    width: 40px;
    height: 40px;
    background-color: #EAEAEA;
    text-align: center;
    border-radius: 50px;
    display: inline-block;
    margin-left: 10px;
    line-height: 38px;
}
.otp-inpt-box{
    text-align: center;
}
.otp-form{
    width: 60px;
    height: 60px;
    border-radius: 9px;
    border: 1px solid #999999;
    color: var(--red-color);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    padding: 20px;
}
.otp-form::placeholder{
font-weight: 700;
    font-size: 20px;
    color: var(--red-color);
}
.otp-form:focus, .otp-form:active{
    border: 1px solid #E3E3E3;
    box-shadow: none;
    outline: none;
}
.resp-none{
    display: none;
}
.resend-text {
    text-align: right;
    margin: 10px 0 15px 0;
}
.resend-text a{
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    color: var(--red-color);
    margin: 0;
    text-decoration: none;
}
.resend-text a span{
    color: #484848;
    cursor: auto;
}
.project-btn.disabled{
    opacity: 0.5;
    pointer-events: none;
}
/* singup-two*/
.singup-two{
    padding: 0;
}
.project-form.bd-example .project-input {
    padding: 15px;
}
button:disabled{
  opacity: .5;
  }
  .pass_format{
    padding: 8px 12px;
    font-size: 13px;
    background: #e5e9ed;
    margin-top: 8px;
    border-radius: 14px;
    display: flex;
    gap: 8px;
  }
  .pass_format svg{
    width: 30px;
    height: 30px;
  }
  .project-input option{
    color: #a8a8a8;
  }
  .project-input option[selected]{
    color: #000;
  }

.text-red{
    color: var(--red-color) !important;
}
app-user{
    position: relative;
}
app-web{
    position: relative;
}
app-footer{
    /* position: absolute; */
    width: 100%;
    /* bottom: 20px;
    left: 0;
    right: 0; */
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #e7eaf0;
    margin: 0 auto;
    display: block;
}
.owl-theme .owl-nav [class*='owl-']:hover{
    background-color: transparent !important;
}
/* report-details */
.report-enquiry{
    margin: 20px 0 20px 0;
}
.report-enquiry h3{
    margin: 0;
    border-bottom: none !important;
    padding-bottom: 10px;
}
.mt-20{
    margin-top: 20px;
}
.view-img{
    margin: 0 0 20px 0;
}
.horizontal-select-box{
    display: flex;
    align-items: center;
}
.horizontal-select-box label{
    margin: 0 15px 0 0;
}
 .form-control:focus{
    border-color: transparent !important;
    box-shadow: none !important;
}
.form-select:focus{
    border-color: #DDDDDD !important;
    box-shadow: none !important;
}
.pagination-sec .disabled{
  opacity: 0.6;
  cursor:default;
}
.custom-upload-btn{
  max-width: 300px;
  margin-left: auto;
  margin-right: auto ;
  position: relative;
}
.custom-upload-btn input{
  position: absolute;
  opacity: 0;
  inset: 0;
  cursor: pointer;
}

 /* comment-sec */
 .comment-sec h5{
    font-size: 15px;
    margin: 0 0 15px 0;
    color: #000;
 }
 .comment-sec ol li{
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
 }
 .reply-date{
    color: #8d8a9c;
    font-size:14px;
 }
 .reply-sec-body{
    display: flex;
 }
 .reply-profile-img{
    height: 3rem;
    width: 3rem;
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
 }
.reply-profile-img img{
    object-fit: cover;
    max-width: 100%;
    border-radius: 50px;
}
.reply-sec-body-text h5{
    font-size: 14px;
    line-height:24px;
    color: #8d8a9c;
    font-weight:300;
    margin: 5px 0 10px 0px;
}
.reply-sec-body-text h5 strong{
    font-size: 16px;
    color: #283d47;
    font-weight: 800;
}
.reply-sec-body-text p{
    font-weight: 400;
    font-size: 13px;
    color: #000;
    margin-bottom: 4px;
}
.reply-sec-body-text ol{
    padding-left: 15px;
}
.reply-sec-body-text ol li{
    font-weight: 400;
    font-size: 13px;
    color: #000;
}
.reply-drop i{
    color: #8d8a9c;
    font-size: 20px;
    cursor: pointer;
}
.reply-drop{
    text-align: right;
}
.profileimg{
    border-radius: 50px;
    height: 24px;
    width: 24px;
    object-fit: cover;
}

.accntProfile{
    display: flex;
    align-items: center;
}
.accntProfile-img{
    margin-right: 15px;
    width: 80px;
    height: 80px;

}
.accntProfile-img img{
    max-width: 100%;
    border-radius: 50px;
    object-fit: cover;
    width: 80px;
    height: 80px;
}
.accntProfile-text h3{
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 5px 0;
    color: #000;

}
.accntProfile-text .btn{
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;
    color: #8C99A7;
    padding: 0;
}
.voterbtn{
    background-color: var(--red-color);
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
}
.verifiedtxt{
    color: green;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 10px;
}
.modal-header h4{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin: 0;
}
.modal.show .modal-dialog {
    padding: 0 1.35rem;

}
.modh{
  background: #12457C;
}
.modtit{
  color:#fff;
}
.btn-close{
  color: #fff !important;
   background: none !important;
   border: 0 !important;
   box-shadow: none !important;
   position: relative !important;
   top: -4px !important;
}
.top10{
  top:10px !important;
  transform:translateY(0) !important;
}
.h4st{
  font-size: 16px;
   color: #A60606;
}
#loading{
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
}

.bill-list-col #loading {
	min-height: unset !important;
}

#loading img{
    height: 8rem;
    width: 8rem;
    margin: 20% auto 0 auto;
}


.report-details-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 14px;
  margin-bottom: 15px;
}

.report-details-title h3{
   border: none;
  padding: 0;
  margin: 0;
}
.report-details-title img{
  width: auto;
  height: 20px;
  cursor: pointer;
}
.showDistrict{
    background-color: transparent !important;
    border-bottom: 2px solid #dee2e6 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}
.carousal-img-radius{
    border-radius:12px 12px 0 0 ;
}
.surveyRadCheckInner{
    max-width: 70%;
    text-align: left;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
}
.surveyRadCheckInner .blue-radio-btn input{
    position:absolute;
}
.surveyRadCheckInner li{
    margin-bottom: 10px !important;
}
.surveybtn-inner{
    gap: 15px;
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
}
.surveybtn-inner button{
    width: auto;
    border-radius: 50px;
    font-size: 14px;
    padding: 15px 40px;
}
.red-btn-two-next{
    border: 2px solid var(--red-color);
    background-color: #fff;
    color: var(--red-color);
    padding: 10px 20px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 700;
}

.description-information{
    padding: 18px;
    text-align: left;
    background-color: #F8FAFC;
    border: 1px solid #BAD4EF;
    border-radius: 12px;
}
.description-information h2{
    font-weight: 700;
    font-size: 15px;
    margin: 0 0 12px 0;
    color: #000;
}
.description-information p{
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin: 0px 0 12px 0;
}
.description-information ul {
    padding: 0;
    margin: 0 0 12px 0;
}
.description-information ul li{
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    color: #3D3D3D;
    font-size: 13px;
    font-weight: 400;
    line-height: 28px;
}
.description-information a{
    margin: 0;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none !important;
    color:#8C99A7;
    cursor: pointer;
}
.surver-vector ul li span.active{
  background-color:#12457c;
}
.no-record-heading{
  text-align: center;
  padding-top: 30px;
}
.no-record-heading h2{
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin: 0;
}
.search-bar-select{
    display: flex;
    gap: 10px;
    align-items: center;
}
.search-bar-select select{
    border-radius: 14px;
    box-shadow: 2px 0px 4px 0px rgba(235, 240, 244, 99%);
    background-color: #fff;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-color);
    border: 1px solid var(--btn-color);
    padding: 20px 60px 20px 30px;
}
.bill-filter .search-bar-select select {
    border-radius: 6px;
}
.top-search-bill .red-btn{
  padding: 20px 30px;
}

.project-form select.project-input{
}
select.form-control:focus{
}
.issueBgClr{
  margin-bottom: 24px;
}
#navbarDropdown{
  text-transform: capitalize;
}
.py-80{
  padding: 80px 0;
}
.mr-10{
  margin-right: 10px !important;
}

.modal_listtxt{
  margin-bottom: 10px;
  display: flex;
  border-bottom: 1px dashed #d3d3d3;
  padding: 5px 10px;
}
.modal_listtxt label{
  font-size: 12px;
  font-weight: 500;
  color: #8c99a7;
  width: 41%;
  flex-shrink: 0;
}
.modal_listtxt span{
  font-size: 12px;
  font-weight: 400;
  color:#12457C;
  margin-left:15px;
}
.pac-container{
  z-index: 999999;
}
.event-para-btn br{display: none;}
.bill-body-text br{display: block;}
.col-red{
  color: var(--red-color);
}
.display-8{
  font-size: 1.5rem;
}
.loopquestion{
  position: relative;
  padding: 15px;
  border: 1px solid #cbcbcb;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #F2F5F8;
}
.loopquestion .circle-time{
  position:absolute ;
  top: -10px;
  right: -10px;
  font-size: 1.5rem;
  color: var(--red-color);
  cursor: pointer;
}

@media print {
  @page {
    size: portrait;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin: 0;
  }

  body,
  html {
    * {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
      print-color-adjust: exact !important;
    }

    .print-hide {
      display: none !important;
    }
  }
}

/* 22.3.24 start */
.card-btn{
  padding-bottom: 14px;
  margin-bottom: 18px;
  border-bottom: 1px solid #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-btn h3{
  margin: 0;
  padding: 0;
  border: none;
}
.card-btn .select-servey{
  border: none;
}
/* 22.3.24 end */
#category-tab-pane .select2-container--bootstrap-5 .select2-selection{
  min-height: calc(2.5em + .75rem + 2px) !important;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected,
 .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted){
background-color: var(--btn-color) !important;
}
/*chat css */
.frame-content {
  display: block;
  border: none;
  position: fixed;
  inset: auto 0px 0px auto;
  width: 0px;
  height: 647px;
  max-height: 100%;
  opacity: 1;
  color-scheme: none;
  background: none transparent !important;
  margin: 0px;
  max-width: 100vw;
  transform: translateY(0px);
  transition: none 0s ease 0s !important;
  visibility: visible;
  z-index: 9!important;
}
.frame-content.openchatbox{
  z-index: 9999 !important;
  width: 450px;
}
.text-left{
  text-align: left !important;
}
.otp-box-highlight{
  border: 1px solid #999999;
}
.otp-box-highlight:focus{
  border: 1px solid #999999;
}

.theme-btn{
  font-size: 15 !important;
    font-weight: 400 !important;
    background-color: var(--btn-color) !important;
    border-radius: 6px;
    padding: 10px;
    color: #fff !important;
    border: none !important;
    cursor: pointer !important;
    display: inline-block !important;
}
.theme-btn:hover{
  background-color: #000 !important;
}
.paynow{
  padding: 10px 25px !important;
  font-size: 16px !important;
}
.global-border-colour{
  border: 1px solid #222 !important;
}
.minhe{
  min-height: 481px;
}
.text-nowrap{
  white-space: nowrap;
}
.surcharge{
  font-size: 15px;
    font-style: italic;
    color: #646464;
}
.bill-filter .search-bar-box input,.bill-filter .search-bar-select select{
  border: 1px solid #ccc;

}
.bill-filter .calender-filter{
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid #ccc;
}
#chat-button {
  position: fixed;
  width: 112px;
  height: 140px;
  bottom: 12px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
  right: 0px;
}

#chat-button:not(.sidebar) .buttonWave {
  position: absolute;
  z-index: -1;
  width: 60px;
  height: 60px;
}

  #chat-button:not(.sidebar).clicked .buttonWave::after {
      animation: 0.5s ease-out 0s 1 normal none running buttonWave;
  }

  #chat-button:not(.sidebar) .buttonWave::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgb(20, 127, 255);
      opacity: 0.5;
  }

#button-body {
  width: 60px;
  height: 60px;
  border-radius: 28px;
  display: inherit;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  pointer-events: initial;
  background-size: 130% 130%;
  transition: all 0.2s ease-in-out 0s;
  position: relative;
  color: rgb(0, 125, 252);
  border: 1px solid #1E88E5;
}

  #button-body::before {
      content: "";
      transition: opacity 0.5s ease-in-out 0s;
      position: absolute;
      inset: -1px;
      opacity: 0;
      border-radius: 50%;
      background-color: rgb(198, 204, 220);
  }

#chat-button button i.for-closed.active {
  transform: translateX(0px);
}

#chat-button button i.for-closed {
  transform: translateX(-10px);
}

#chat-button button i.active {
  opacity: 1;
}

#chat-button button i {
  height: 26px;
  width: 26px;
  position: absolute;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  /* justify-content: center; */
}

.frame-content i {
  user-select: none;
}

#chat-button button i.type1 svg {
  fill: currentcolor;
}

.frame-content svg {
  width: 24px;
  height: 24px;
}

#chat-button button i.type1::after {
  content: "";
  position: absolute;
  width: 68px;
  height: 68px;
  border-radius: 32px;
  background: rgb(255, 255, 255);
  transition: all 0.2s ease-in-out 0s;
  transform: scale(0);
  right: -18px;
}

#chat-button button i.for-closed.active {
  transform: translateX(0px);
}

#chat-button button i.for-closed {
  transform: translateX(-10px);
}

#chat-button button i.type2 {
  width: 32px;
  height: 32px;
}

#button button i.type1.for-opened {
  width: 31px;
  height: 28px;
}

#chat-button button i.for-opened {
  transform: translateX(10px);
}

.frame-content button, .frame-content button.material-icons {
  background: none;
  border: 0px;
  color: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0px;
  user-select: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


#chat-button button i.type2 svg {
  width: 32px;
  height: 32px;
  fill: rgb(0, 125, 252);
  transform: scale(0);
}

.frame-content svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

#button-body {
  box-shadow: rgba(0, 77, 255, 0.5) 0px 4px 24px;
}

body:not(.mobile) #button button:not(.disabled):hover i.type1 svg, body:not(.mobile) #button button:not(.disabled):focus i.type1 svg {
  transform: scale(1.4);
}

body:not(.mobile) #button button:not(.disabled):hover i.type1::after, body:not(.mobile) #button button:not(.disabled):focus i.type1::after {
  transform: scale(1);
}

#chat-button button i.for-closed.active {
  transform: translateX(0px);
}

.chat.chrome, .start-group.chrome {
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 36px 0px;
}

.chat {
  max-height: calc(100% - 47px);
  display: flex;
  flex-direction: column;
}

.chat, .start-group {
  width: 372px;
  position: fixed;
  bottom: 26px;
  border-radius: 16px;
  pointer-events: auto;
  box-shadow: rgba(0, 18, 46, 0.16) 0px 8px 22px 0px;
  overflow: hidden;
  z-index: 999;
  right: 48px;
  left: auto;
  background-color: white;
}

.message-operator.bots-quick-replies {
  width: 85%;
  background-color: rgb(255, 255, 255);
  margin-top: 0px;
  float: right;
}

  .message-operator.bots-quick-replies .button-wrapper {
      margin-top: 0px;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: end;
      justify-content: flex-end;
      width: 100%;
      border: none;
  }

.message-operator {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: left;
}

.message {
  padding: 10px 16px;
  border-radius: 20px;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
}

.input-group {
  padding: 0px 28px 6px;
  width: 100%;
  position: relative;
  background: rgb(255, 255, 255);
  z-index: 3;
  flex: 0 0 auto;
}
  .input-group .footer-input-wrapper, .input-group .footer-icons-wrapper {
      transition: all 0.5s ease-in-out 0s;
      opacity: 1;
      transform: translateY(0px);
  }

  .footer-input-wrapper textarea {
border: 1px solid #ccc;
border-radius: 10px;
 padding: 5px 10px;
  width: 100%;
  font-size: 13px;
  margin: 20px 0px 14px;
  resize: none;
  line-height: 20px;
  height: 50px;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  outline:none;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
}

.send-icon {
  width: 26px;
  height: 26px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26px;
  flex: 0 0 26px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.frame-content hr {
  margin: 0px;
  border-width: 0px 0px 1px;
  border-top-style: initial;
  border-right-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-bottom-style: solid;
  border-bottom-color: rgb(219, 223, 230);
}

#conversation-group {
  padding: 0px 28px;
  width: 100%;
  height: 357px;
  overflow: hidden auto;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  max-height: 357px;
  min-height: 160px;
  flex: 0 1 auto;
}
.message-operator.bots-quick-replies button {
  font-size: 15px;
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 20px;
  margin: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: inherit;
}

.offline-message span.online::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 4px);
  background: rgb(88, 183, 67);
  border-radius: 50%;
  left: 0px;
}

.no-clip-path .offline-message {
  padding: 14px 28px 20px;
}

.offline-message span {
  z-index: 2;
  position: relative;
  display: inline-block;
  font-size: 16px;
}

  .message-operator.message-with-buttons .button-wrapper, .message-operator .message-with-buttons .button-wrapper, .message-operator.bots-quick-replies .button-wrapper {
      background: rgb(255, 255, 255);
      width: 100%;
      margin-top: 10px;

      border-image: initial;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-top-style: initial;
      border-top-color: initial;
      position: relative;
  }

#chat-button button i.for-opened.active {
  transform: translateX(0px);
}
  .message-operator.bots-quick-replies .sent {
      font-size: 15px;
      padding: 8px 16px;
      border: none;
      border-radius: 20px;
      margin: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: inherit;
  }
.message-operator.message-with-buttons .sent, .message-operator .message-with-buttons .sent, .message-operator.bots-quick-replies .sent {
  margin: 0px auto;
  min-width: 100%;
  display: block;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  color: rgb(0, 125, 252);
  background: #f2f9ff;
  position: relative;
  z-index: 2;
  outline: none;
  border-radius: 20px;
  font-weight:normal;
}


.chat-header {
  padding: 24px 28px 0px;
  background: linear-gradient(-61deg, rgb(0, 224, 216), rgb(32, 29, 142));
  position: relative;
  z-index: 4;
  flex: 0 0 auto;
}

.avatars-wrapper {
  width: 52px;
  height: 52px;
  margin: 0px 18px 0px 0px;
  float: left;
}

.chat h2.oneline {
  margin-top: 0px;
  line-height: 52px;
  min-height: 52px;
}

.chat h2 {
  font-size: 22px;
  font-weight: 600;
  color: currentcolor;
  margin: 4px 0px 0px;
  padding: 0px;
  display: inline-block;
  position: relative;
  max-width: calc(100% - 145px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;

}

  .chat h2 .emoji {
      width: 31px;
      height: 31px;
  }

button.material-icons.exit-chat, label.material-icons.exit-chat {
  margin-right: -3px;
}
button.material-icons.options, button.material-icons.exit-chat, label.material-icons.options, label.material-icons.exit-chat {
  z-index: unset;
}

button.material-icons, label.material-icons {
  position: relative;
  z-index: 1;
  margin: 15px 0px 8px 11px;
  float: right;
}
  button.material-icons.options::before, button.material-icons.exit-chat::before, label.material-icons.options::before, label.material-icons.exit-chat::before {
      background: rgba(0, 36, 92, 0.16);
  }
  button.material-icons::before, label.material-icons::before {
      content: "";
      position: absolute;
      background: rgb(239, 242, 246);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      z-index: -1;
      transition: all 0.16s ease-in-out 0s;
      transform: scale(0);
      top: calc(50% - 20px);
      left: calc(50% - 20px);
  }
  button.material-icons svg#ic-minimize, button.material-icons svg.options-icon, label.material-icons svg#ic-minimize, label.material-icons svg.options-icon {
      fill: currentcolor;
  }




.tidio-1s5t5ku span {
  background: rgb(255, 255, 255);
  padding: 6px 8px;
  border-radius: 2px;
  box-shadow: rgba(0, 18, 46, 0.32) 0px 2px 8px 0px;
  font-size: 13px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  transition: all 0.16s ease-in-out 0s;
  z-index: 1;
  right: calc(100% + 10px);
  top: 50%;
  transform: translate(5px, -50%);
  color: rgb(6, 19, 43);
}

.offline-message {
  margin: 18px -28px 0px;
  color: currentcolor;
  width: calc(100% + 56px);
  padding: 14px 28px 7px;
  position: relative;
  background-size: 100% calc(100% + 12px);
  z-index: 1;
}

.no-clip-path .offline-message {
  padding: 14px 28px 20px;
}

.offline-message span.online {
  padding-left: 20px;
}

.no-clip-path .offline-message::after {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  bottom: -8px;
  left: -5px;
  border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
  border-image-slice: 0 0 100%;
  border-image-width: 0 0 15px;
  border-image-repeat: stretch;
  border-width: 0px 0px 15px;
  border-bottom-style: solid;
  border-color: initial;
  border-top-style: initial;
  border-left-style: initial;
  border-right-style: initial;
}

.input-group .footer-input-wrapper, .input-group .footer-icons-wrapper {
  transition: all 0.5s ease-in-out 0s;
  opacity: 1;
  transform: translateY(0px);
}

.send-icon:hover svg path {
  fill: #1775ec;
}

.message-operator {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  float: left;
}
.message span.message-content {
  white-space: pre-line;
}
#chatContainer {
  transition: all 0.3s ease-in-out;
  right:-300px;

}

.onlyBubble {
  display: none;
  transition: all 0.3s ease-in-out;
}

#chatContainer.open {
  display: block;
  transition: all 0.3s ease-in-out;
}

#chatContainer.open {
  right: 0;
}

.footer-input-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.frame-content .clicked {
  display: none !important;
}

#button:not(.sidebar).clicked .buttonWave::after {
  animation: 0.5s ease-out 0s 1 normal none running buttonWave;
}
#button:not(.sidebar) .buttonWave::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(20, 127, 255);
  opacity: 0.5;
}

.sentText {
  margin: 0px auto;
  min-width: 100%;
  display: block;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 16px;
  color: rgb(0, 125, 252);
  background: #f2f9ff;
  position: relative;
  z-index: 2;
  outline: none;
  border-radius: 20px;
  font-weight: normal;
  margin: 10px;
}

.botText {
  color: rgb(6, 19, 43);
  background: rgb(240, 242, 247);
  padding: 10px 16px;
  border-radius: 20px;
  margin: 2px 0px;
  font-size: 15px;
  line-height: 20px;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 85%;
  clear: both;
  position: relative;
  transition: margin 0.28s ease-in-out 0s;
}

.svgsent svg path {
  fill: #1775ec;
}

.event-tab .tab-pane {
    border-radius: 6px;
}

.bill-list-col {
    min-height: 100vh;
}

/*chat css */
